<template>
<div v-if="data">
            <div v-if="data.payment_completed.id" class="card mt-2  mb-3 pl-0 pr-0 pt-0 b-1">
                <h5 class="p-3 bb-2 head-color">Payment Completed</h5>
                <div class="row pl-3 pt-3 pr-5">
                    <div class="col-2 label">Purchase ID</div>
                    <div v-if="data.payment_completed.id" class="col-2 p-1 b-1 text-muted">{{ data.payment_completed.id }}</div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                    <div class="col-2 label">Approved By</div>
                    <div v-if="data.payment_completed.approved_by" class="col-2 p-1 b-1 text-muted">{{
                            data.payment_completed.approved_by
                        }}
                    </div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                    <div class="col-2 label">Date</div>
                    <div v-if="data.payment_completed.approved_date" class="col-2 p-1 b-1 text-muted">{{ data.payment_completed.approved_date }}</div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                </div>
            </div>
            <div v-if="data.finance_approved.id" class="card mt-2  mb-3 pl-0 pr-0 pt-0 b-1">
                <h5 class="p-3 bb-2 head-color">FA Approved</h5>
                <div class="row pl-3 pt-3 pr-5">
                    <div class="col-2 label">Purchase ID</div>
                    <div v-if="data.finance_approved.id" class="col-2 p-1 b-1 text-muted">{{ data.finance_approved.id }}</div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                    <div class="col-2 label">Approved By</div>
                    <div v-if="data.finance_approved.approved_by" class="col-2 p-1 b-1 text-muted">{{
                            data.finance_approved.approved_by
                        }}
                    </div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                    <div class="col-2 label">Date</div>
                    <div v-if="data.finance_approved.approved_date" class="col-2 p-1 b-1 text-muted">{{ data.finance_approved.approved_date }}</div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                </div>
            </div>
            <div v-if="data.finance_rejected.id" class="card mt-2  mb-3 pl-0 pr-0 pt-0 b-1">
                <h5 class="p-3 bb-2 head-color">FA Approved</h5>
                <div class="row pl-3 pt-3 pr-5">
                    <div class="col-2 label">Purchase ID</div>
                    <div v-if="data.finance_rejected.id" class="col-2 p-1 b-1 text-muted">{{ data.finance_rejected.id }}</div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                    <div class="col-2 label">Approved By</div>
                    <div v-if="data.finance_rejected.approved_by" class="col-2 p-1 b-1 text-muted">{{
                            data.finance_rejected.approved_by
                        }}
                    </div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                    <div class="col-2 label">Date</div>
                    <div v-if="data.finance_rejected.approved_date" class="col-2 p-1 b-1 text-muted">{{ data.finance_rejected.approved_date }}</div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                </div>
            </div>
            <div v-if="data.purchase_rejected.id" class="card mt-2  mb-3 pl-0 pr-0 pt-0 b-1">
                <h5 class="p-3 bb-2 head-color">PA Rejected</h5>
                <div class="row pl-3 pt-3 pr-5">
                    <div class="col-2 label">Purchase ID</div>
                    <div v-if="data.purchase_rejected.id" class="col-2 p-1 b-1 text-muted">{{ data.purchase_rejected.id }}</div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                    <div class="col-2 label">Rejected By</div>
                    <div v-if="data.purchase_rejected.approved_by" class="col-2 p-1 b-1 text-muted">{{
                            data.purchase_rejected.approved_by
                        }}
                    </div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                    <div class="col-2 label">Date</div>
                    <div v-if="data.purchase_rejected.approved_date" class="col-2 p-1 b-1 text-muted">{{ data.purchase_rejected.approved_date }}</div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                    <div class="col-2 label">Date</div>
                    <div v-if="data.purchase_rejected.approved_date" class="col-2 p-1 b-1 text-muted">{{ data.purchase_rejected.approved_date }}</div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                </div>
            </div>
            <div v-if="data.purchase_approved.id" class="card mt-2  mb-3 pl-0 pr-0 pt-0 b-1">
                <h5 class="p-3 bb-2 head-color">PA Approved</h5>
                <div class="row pl-3 pt-3 pr-5">
                    <div class="col-2 label">Purchase ID</div>
                    <div v-if="data.purchase_approved.id" class="col-2 p-1 b-1 text-muted">{{ data.purchase_approved.id }}</div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                    <div class="col-2 label">Approved By</div>
                    <div v-if="data.purchase_approved.approved_by" class="col-2 p-1 b-1 text-muted">{{
                            data.purchase_approved.approved_by
                        }}
                    </div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                    <div class="col-2 label">Approved Date</div>
                    <div v-if="data.purchase_approved.approved_date" class="col-2 p-1 b-1 text-muted">{{ data.purchase_approved.approved_date }}</div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                </div>
            </div>
            <div v-if="data.created.id" class="card mt-2  mb-3 pl-0 pr-0 pt-0 b-1">
                <h5 class="p-3 bb-2 head-color">Created</h5>
                <div class="row pl-3 pt-3 pr-5">
                    <div class="col-2 label">Purchase ID</div>
                    <div v-if="data.created.id" class="col-2 p-1 b-1 text-muted">{{ data.created.id }}</div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                    <div class="col-2 label">Created By</div>
                    <div v-if="data.created.created_by" class="col-2 p-1 b-1 text-muted">{{
                            data.created.created_by
                        }}
                    </div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                    <div class="col-2 label">Created Date</div>
                    <div v-if="data.created.created_date" class="col-2 p-1 b-1 text-muted">{{ data.created.created_date }}</div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                </div>
            </div>
        </div>
</template>

<script>
export default {
    name  : 'HistoryPage',
    props : {
        data : { type : Array }
    }
};
</script>

<style scoped>
.b-1 {
    border-radius: 4px;
}

.head-color {
    background-color: #eeeeeea8;
}

.label {
    font-weight: 500;
}
</style>
